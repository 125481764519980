import {AddToCartButtonIcon} from '../../../../../types/galleryTypes';
import {Cart, CartFill, Plus, ShoppingBag} from '@wix/wix-ui-icons-common/on-stage';

export const useAddToCartIcon = () => {
  const iconsMapper = {
    [AddToCartButtonIcon.ShoppingBag]: ShoppingBag,
    [AddToCartButtonIcon.Plus]: Plus,
    [AddToCartButtonIcon.Cart]: Cart,
    [AddToCartButtonIcon.CartFill]: CartFill,
  };

  return {iconsMapper};
};
